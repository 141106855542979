import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"
class BAPage extends React.Component {
      openServicePopup(event) {
        const divsToHide = document.getElementsByClassName('service-popup')
        for(var i = 0; i < divsToHide.length; i++){
            divsToHide[i].style.display = "none";
        }
        document.getElementById('js--' + event.target.getAttribute('data-id')).style.display = 'block'
      }
      closeServicePopup() {
        const divsToHide = document.getElementsByClassName('service-popup')
        for(var i = 0; i < divsToHide.length; i++){
            divsToHide[i].style.display = "none";
        }
      }
    render() {
        return(
    <Layout>
    <SEO title="Before and After Smile Makeover – Cosmetic Dentist Toronto"
     description="The Smile Stylist can help you to achieve the smile of your dreams. View these Before and After images of our patients who have completely transformed their smiles."
      keywords="Cosmetic teeth results, smile makeover, cosmetic dentist toronto" />
    <main className="site-content beaf" role="main">
    <section id="main-top-img" className="before-after hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Every smile is a work of art…</h1>
            <h2>Our Smile Stylist can help you to achieve the smile of your dreams.</h2>
          </div>
        </div>
      </div>
    </section>
    <div id="js--1" className="service-popup">
      <div className="service-popup__containter">
        <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
        <p className="service-popup__title">
          Gum Lift + Porcelain Veneers
        </p>
        <div className="service-popup__img-container">
          <img onClick={this.openServicePopup} data-id="24" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
          <img className="service-popup__image" src={ require("../images/desktop/services/ba-gum.jpg") } title="Gum Lift + Porcelain Veneers" />
          <img onClick={this.openServicePopup} data-id="2" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
        </div>
        <p className="service-popup__desc">
          Gum lifting, also known as "gingivectomy", is a cosmetic dentistry procedure which improves the aesthetics of your smile by ensuring the gum line of your teeth is in the best possible position to show off your teeth.
        </p>
        <div className="service-popup__btns">
          <a href="/gum-lift-procedures" className="service-popup__btn_black">Learn More</a>
          <a href="/contact" className="service-popup__btn_red">Free Consult</a>
        </div>
      </div>
    </div>
    <div id="js--2" className="service-popup">
      <div className="service-popup__containter">
        <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
        <p className="service-popup__title">
          Porcelain Veneers
        </p>
        <div className="service-popup__img-container">
          <img onClick={this.openServicePopup} data-id="1" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
          <img className="service-popup__image" src={ require("../images/desktop/services/porcelain-veneers-before-after.png") } />
          <img onClick={this.openServicePopup} data-id="3" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
        </div>
        <p className="service-popup__desc">
          Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
        </p>
        <div className="service-popup__btns">
          <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
          <a href="/contact" className="service-popup__btn_red">Free Consult</a>
        </div>
      </div>
    </div>
    <div id="js--3" className="service-popup">
      <div className="service-popup__containter">
        <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
        <p className="service-popup__title">
          ClearCorrect Invisible Braces
        </p>
        <div className="service-popup__img-container">
          <img onClick={this.openServicePopup} data-id="2" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
          <img className="service-popup__image" src={ require("../images/desktop/services/ClearCorrect Invisible Braces.png") } />
          <img onClick={this.openServicePopup} data-id="4" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
        </div>
        <p className="service-popup__desc">
          Cosmetic teeth whitening is a big part of our practice.
        </p>
        <div className="service-popup__btns">
          <a href="/teeth-whitening" className="service-popup__btn_black">Learn More</a>
          <a href="/contact" className="service-popup__btn_red">Free Consult</a>
        </div>
      </div>
    </div>
    <div id="js--4" className="service-popup">
      <div className="service-popup__containter">
        <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
        <p className="service-popup__title">
          Gum Lift Procedures
        </p>
        <div className="service-popup__img-container">
          <img onClick={this.openServicePopup} data-id="3" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
          <img className="service-popup__image" src={ require("../images/desktop/services/ba-veneers1.jpg") } title="Gum Lift + Porcelain Veneers" />
          <img onClick={this.openServicePopup} data-id="5" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
        </div>
        <p className="service-popup__desc">
          Gum lifting, also known as "gingivectomy", is a cosmetic dentistry procedure which improves the aesthetics of your smile by ensuring the gum line of your teeth is in the best possible position to show off your teeth.
        </p>
        <div className="service-popup__btns">
          <a href="/gum-lift-procedures" className="service-popup__btn_black">Learn More</a>
          <a href="/contact" className="service-popup__btn_red">Free Consult</a>
        </div>
      </div>
    </div>
    <section id="main-white" className="info_main">
      <div id="js--5" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Dental Implants
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="4" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Dental Implants.png") } />
            <img onClick={this.openServicePopup} data-id="6" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.
          </p>
          <div className="service-popup__btns">
            <a href="/full-mouth-reconstruction" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--6" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="5" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Caroline Before After.png") } title="Caroline Before After" />
            <img onClick={this.openServicePopup} data-id="7" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--7" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="6" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Porcelain Veneers 3.png") } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="8" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--8" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="7" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Porcelain Veneers 4.png") } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="9" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--9" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Implants + Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="7" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Implants Porcelain Veneers.png") } title="Implants + Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="10" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--11" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="10" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Porcelain Veneers 5.png") } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="12" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--12" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="11" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Porcelain Veneers 6.png") } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="13" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--13" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Gapped Teeth + Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="12" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Veneers 7.png") } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="14" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--14" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="13" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Veneers 8.png") } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="15" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--15" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="14" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Veneers 9.png") } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="16" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--16" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Full Mouth Reconstruction
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="15" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Full Mouth Reconstruction.png") } />
            <img onClick={this.openServicePopup} data-id="17" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.
          </p>
          <div className="service-popup__btns">
            <a href="/full-mouth-reconstruction" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--17" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="16" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Ashley Before - After.png") } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="18" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--18" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="17" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Veneers 10.png") } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="19" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--19" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="18" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Veneers 11.png") } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="20" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--20" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="19" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Veneers 12.png") } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="21" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--21" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Full Mouth Reconstruction
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="20" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Full Mouth Reconstruction 2.png") } />
            <img onClick={this.openServicePopup} data-id="22" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.
          </p>
          <div className="service-popup__btns">
            <a href="/full-mouth-reconstruction" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--22" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Dental Implants
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="21" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Dental Implants 2.png") } title="Dental Implants" />
            <img onClick={this.openServicePopup} data-id="23" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.
          </p>
          <div className="service-popup__btns">
            <a href="/full-mouth-reconstruction" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--23" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="22" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Veneers 13.png") } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="24" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--10" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="23" className="service-popup__arrow_left js--open-popup" src={ require("../images/desktop/arrow-left.svg") } />
            <img className="service-popup__image" src={ require("../images/desktop/services/Before After Veneers 14.png") } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="1" className="service-popup__arrow_right js--open-popup" src={ require("../images/desktop/arrow-right.svg") } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div className="container-fluid __no-pad">
        <div className="section_white">
          <div className="container">
            <div className="row txtsec">
              <p>
                Our patented smile design process was created to ensure that all of our patients receive the unforgettable smile that they have always
dreamed of. Our process is very thorough; we take into account your facial features and the structure of your smile, from the colour of
your eyes, the shape of your jaw, size of your lips, length of your neck and even personality type to perfectly tailor and craft your very
own smile. The result will be a stunning new smile that looks natural. improves your oral health and gives you that Rockstar confidence
that you truly deserve. <a href="/contact" className="__no-hov"><strong>Book your FREE consultation</strong></a> today to speak with <a href="/about-dr-kaloti">Dr. Kaloti</a> about any of our life changing cosmetic procedures.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/gum-lift-procedures'>Gum Lift</a> + <a className="__no-hov" href="/porcelain-veneers">Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="1" src={ require("../images/desktop/services/ba-gum.jpg") } title="Gum Lift + Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="2" src={ require("../images/desktop/services/porcelain-veneers-before-after.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">

                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/invisible-braces'>ClearCorrect Invisible Braces</a></h3>
                <img onClick={this.openServicePopup} data-id="3" src={ require("../images/desktop/services/ClearCorrect Invisible Braces.png") } title="ClearCorrect Invisible Braces" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/gum-lift-procedures'>Gum Lift</a> + <a className="__no-hov" href="/porcelain-veneers">Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="4" src={ require("../images/desktop/services/ba-veneers1.jpg") } title="Gum Lift + Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/dental-implants'>Dental Implants</a></h3>
                <img onClick={this.openServicePopup} data-id="5" src={ require("../images/desktop/services/Dental Implants.png") } title="Dental Implants" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="6" src={ require("../images/desktop/services/Caroline Before After.png") } title="Caroline Before After" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="7" src={ require("../images/desktop/services/Before After Porcelain Veneers 3.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="8" src={ require("../images/desktop/services/Before After Porcelain Veneers 4.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'><a className="__no-hov" href='/dental-implants'>Implants</a> + Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="9" src={ require("../images/desktop/services/Implants Porcelain Veneers.png") } title="Implants + Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
            </div>
            <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="10" src={ require("../images/desktop/services/Before After Veneers 14.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="11" src={ require("../images/desktop/services/Before After Porcelain Veneers 5.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="12" src={ require("../images/desktop/services/Before After Porcelain Veneers 6.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
            
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/gaps-between-teeth'>Gaps</a> + <a className="__no-hov" href="/porcelain-veneers">Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="13" src={ require("../images/desktop/services/Before After Veneers 7.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="14" src={ require("../images/desktop/services/Before After Veneers 8.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="15" src={ require("../images/desktop/services/Before After Veneers 9.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href="/full-mouth-reconstruction">Full Mouth Reconstruction</a></h3>
                <img onClick={this.openServicePopup} data-id="16" src={ require("../images/desktop/services/Full Mouth Reconstruction.png") } title="Full Mouth Reconstruction" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="17" src={ require("../images/desktop/services/Ashley Before - After.png") } title="Ashley Before - After" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="18" src={ require("../images/desktop/services/Before After Veneers 10.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="19" src={ require("../images/desktop/services/Before After Veneers 11.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="20" src={ require("../images/desktop/services/Before After Veneers 12.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href="/full-mouth-reconstruction">Full Mouth Reconstruction</a></h3>
                <img onClick={this.openServicePopup} data-id="21" src={ require("../images/desktop/services/Full Mouth Reconstruction 2.png") } title="Full Mouth Reconstruction" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/dental-implants'>Dental Implants</a></h3>
                <img onClick={this.openServicePopup} data-id="22" src={ require("../images/desktop/services/Dental Implants 2.png") } title="Dental Implants" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <h3 className="main_heading__black main_heading__top"><a className="__no-hov" href='/porcelain-veneers'>Porcelain Veneers</a></h3>
                <img onClick={this.openServicePopup} data-id="23" src={ require("../images/desktop/services/Before After Veneers 13.png") } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
            </div>
            <div className="row">
              <a className="btn btn-bottom" href="/contact">Free Consultation</a>



            </div>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>

    </section>
    <section id="before-after-info" className="info__section_bg">
      <div className="info__clickable-img">
        <iframe className="info__img" src="https://www.youtube.com/embed/ImV5ByMuZS4?rel=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
      </div>
    </section>
  </main>
  </Layout>
        )
    }
}

export default BAPage